<template>
  <footer>
    <v-container>
      <v-row>
        <v-col align="center">
          <span class="text-grey-lighten-4">Creative-wave. {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>


<style lang="sass">
footer 
  background: #231F20
  font-size: 15px
  line-height: 152%
  padding: 50px 0 20px
  
  .powered
    color: #979797
 
</style>