<template>
  <header>
    <v-container>
      <v-row justify-between>

        <v-col>
          <div class="text-right">
            <template v-if="status == 'unauthenticated'">
              <nuxt-link class="mr-5 text-white" to="/auth/login">Войти</nuxt-link>
              <nuxt-link class="text-white" to="/auth/register">Регистрация</nuxt-link>
            </template>

          </div>

        </v-col>
      </v-row>
    </v-container>
  </header>
</template>
 
<script setup>

const {
  status
} = useAuth()


</script>

<style lang="sass">
header 
  background: #231F20
  color: #fff
  padding: 18px 0
 
</style>